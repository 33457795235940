.brand-category {
  display: flex;
  gap: 0.5rem;
}
.category-select .ant-select-selector {
  height: 3rem !important;
}

.brand-table .ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.brand-table figure {
  margin: 0 !important;
}
.product-list-action .ant-select {
  width: 10rem !important;
  margin-bottom: 0 !important;
}
