.user-info img {
  width: 226px;
  object-fit: contain;
}
.user-info .info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info p {
  margin-bottom: 0;
}

.info-title {
  font-weight: bold;
  width: 100px;
}
